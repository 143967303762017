import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GymRegister from './register/Register';
import Login from './login/Login';
import GymDisplay from './profile/Profile';
import BookingPage from './booking/Booking';
import HomePage from './home/Home';
import Dashboard from './visualise/Visualise';
import Notifications from './notification/Notification';
import EquipmentPage from './equipment/Equipment';
import SlotPage from './slots/Slots';
import GymImages from './gymImages/GymImages';
import MySubscriptionPage from './subscription/MySubscription';
import QRCodeReader from './scan/QRCodeReader';
import PrivacyPolicy from './privacy/Privacy';
import BankAccountModificationPage from './banking/Banking';
import VerifyEmail from './login/VerifyEmail';
import ForgotPassword from './login/ForgotPassword';
import ResetPassword from './login/ResetPassword';
import TermsAndCondition from './privacy/TermsAndCondition';
import AdminLogin from './login/AdminLogin';
import AdminBookingPage from './booking/AdminBookingPage';
import PaymentHistory from './booking/PaymentHistory';
import UserBookingPage from './booking/UserBooking';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/register" element={<GymRegister />} />
                <Route path="/profile" element={<GymDisplay />} />
                <Route path="/bookings" element={<UserBookingPage />} />
                <Route path="/booking" element={<BookingPage />} />
                <Route path="/admin_booking" element={<AdminBookingPage />} />
                <Route path="/visual" element={<Dashboard />} />
                <Route path="/equipment" element={<EquipmentPage />} />
                <Route path="/slots" element={<SlotPage />} /> 
                <Route path="/gallery" element={<GymImages />} /> 
                <Route path="/subscription" element={<MySubscriptionPage />} /> 
                <Route path="/scan" element={<QRCodeReader />} /> 
                <Route path="/notifications" element={<Notifications />} /> {/* Add the notifications route */}
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/terms" element={<TermsAndCondition />} />
                <Route path="/banking" element={<BankAccountModificationPage />} />
                <Route path="/admin" element={<AdminLogin />} />
            </Routes>
        </Router>
    );
}

export default App;
